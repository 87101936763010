import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching2 from '../../components/images/imageCoaching2.js'
import ImageDevelopement2 from '../../components/images/imageDevelopement2.js'
import ImageTransition from '../../components/images/imageTransition.js'
import ImageLeft5 from '../../components/images/imageLeft5.js'

const Recherche = () => (
  <Layout>
    <SEO title="Soutien à la recherche d'un emploi emballant | Coaching | Gestion Mieux-Être" />
    <PageHeader
        header="Soutien à la recherche d'un emploi emballant"
        text="Nous pouvons vous guider dans cette course à obstacles qu'est la recherche d'un emploi. S'appuyant sur votre parcours professionnel, nous vous offrons un accompagnement personnalisé répondant spécifiquement à votre profil professionnel. "
        quote="Le meilleur moyen de prédire le futur, c’est de le créer <span>- Peter Drucker</span>"
        background="coaching" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">

        <p class="service-presentation2">Oui, c’est possible de vous démarquer pour décrocher cet emploi que vous convoitez tant!  Que diriez-vous que l'on vous seconde afin de :</p>
        <div className="flex-container pad2">
            <div className="half">
                <ul className="success">
                    <li>Préciser vos objectifs professionnels</li>
                    <li>Établir la bonne stratégie de recherche d’emploi</li>
                    <li>Analyser le marché de l'emploi</li>
                    <li>Ajouter une touche de professionnalisme à votre curriculum vitæ</li>
                    <li>Rédiger une lettre de motivation percutante</li>
                    <li>Préparer avec succès votre entrevue de sélection</li>
                </ul>
            </div>
            <div className="half"><ImageLeft5 /></div>
        </div>
        <p class="service-presentation2">Renforcer votre confiance et assurance, bien sûr que c’est possible!   Vous avez les talents et compétences pour vous démarquer: il vous faut maintenant le mettre en évidence… et ainsi concrétiser les possibilités qui s’offrent à vous.</p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>
        <div className="upperline"></div>
        <br /><br />
        <h1>Services connexes</h1>
        <br /><br /><br /><br />
        <div className="flex-container2">
            <ScrollAnimation
                delay="20"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/developpement-des-competences">
                    <ImageDevelopement2 />
                    <h6>Développement de compétences relationnelles ou de gestion</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="30"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/integration-a-de-nouvelles-fonctions">
                    <ImageCoaching2 />
                    <h6>Intégration à de nouvelles fonctions de gestion</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="40"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/transition-de-carriere">
                    <ImageTransition />
                    <h6>Transition de carrière</h6>
                </Link>
            </div>
            </ScrollAnimation>
        </div>
    </div>
  </Layout>
)

export default Recherche
